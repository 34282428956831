<template>
  <div>
    <ul
      class="list-group list-group-flush list my--3"
      v-loading="loading"
    >
      <li
        v-for="venue in shortlists"
        :key="venue.thumbnail"
        class="list-group-item px-0"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <span class="avatar">
              <img alt="Image placeholder" :src="venue.thumbnail" />
            </span>
          </div>
          <div class="col">
            <h5 class="mb-0">{{ venue.name }}</h5>
          </div>
          <div class="mr-3" @click="removeShortlist(venue.id)">
            <badge
              rounded tag="a" class="btn-remove" href="javascript:"
              type="danger">
              ✕ Remove 
            </badge>
          </div>
        </div>
      </li>
    </ul>
    <p class="text-center  mt-4" v-if="shortlists.length == 0">No Venues</p>
  </div>
</template>
<script>
export default {
  name: "venue-shortlist",
  props: {
    leadEventId: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    shortlists() {
      return this.$store.getters['shortlist/getShortListByLeadEventId'](this.leadEventId);
    },
  },
  async created() {
    await this.$store.dispatch("shortlist/getShortlist", this.leadEventId);
    this.loading = false;
  },
  methods: {
    async removeShortlist(venueId) {
      this.loading = true;
      await this.$store.dispatch("shortlist/removeShortlistVenue", {
        leadEventId: this.leadEventId,
        venueId: venueId,
      });
      this.loading = false;
    },
  },
};
</script>

<style>
.btn-remove {
  visibility: hidden;
  opacity: 0;
  transition: opacity, 0.8s;
}

li:hover .btn-remove {
  visibility: visible;
  opacity: 1;
  transition: opacity, 0.8s;
}
</style>
