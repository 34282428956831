<template>
  <div class="content">
    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <router-link :to="{ name: 'LeadDetails', params: { id: leadId }}">
              <h1 class="display-3 text-white">Lead #{{ leadId }}</h1>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-4">
                <h3 class="mb-0" id="manage_shortlist_heading">Manage Lead Inspections</h3>
              </div>
              <div class="col-8 text-right">
                <a
                  href="#"
                  class="btn btn-sm btn-primary"
                  id="venue_list_view"
                  @click="swapComponent('listView')"
                ><i class="fas fa-list"></i> List View</a
                >
                <a
                  href="#"
                  class="btn btn-sm btn-primary"
                  id=venue_map_view
                  @click="swapComponent('mapView')"
                ><i class="far fa-map"></i> Map View</a
                >
                <a
                  href="#"
                  class="btn btn-sm btn-primary"
                  id="create_inspection_button"
                  @click="createInspection()"
                ><i class="ni ni-calendar-grid-58"></i> Create Inspection Request
                </a>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-3" id="filters_and_shortlisted">
                  <form>
                    <h6 class="heading-small text-muted mb-4">Search</h6>
                    <div class="row">
                      <div class="col-lg-12">
                        <base-input
                          class="custom-search input-group-lg input-group-flush mb-0"
                          prepend-icon="fas fa-search"
                          placeholder="Search..."
                          id="search_input"
                          type="search"
                          v-model="searchQuery"
                        />
                      </div>
                    </div>

                    <hr />

                    <collapse class="custom-filters" :active-index="0">
                      <collapse-item name="1">
                        <h6 slot="title" class="heading-small text-muted mb-0">
                          Filters
                        </h6>

                        <div>
                          <lead-filters
                            id="country_filters"
                            :country.sync="country"
                            :venues="venues"
                            @filterData="updateVenues"
                          />
                        </div>
                      </collapse-item>
                    </collapse>
                  </form>

                  <hr />

                  <h6 class="heading-small text-muted mb-4">
                    Shortlisted Venues
                  </h6>

                  <div class="row">
                    <div class="col-lg-12" id="shortlisted_venues">
                      <venue-shortlist :lead-event-id="leadEventId" ></venue-shortlist>
                    </div>
                  </div>
                </div>

                <div class="col-9" id="venue_list">
                  <h6 class="heading-small text-muted mb-4">Venue List</h6>
                  <keep-alive>
                    <component
                      :is="currentComponent"
                      :swap-component="swapComponent"
                      :venues="filteredVenuesData"
                      :lead-id="leadId"
                      :lead-event-id="leadEventId" 
                    />
                
                  </keep-alive>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import Tabs from "@/components/Tabs/Tabs";
import Tab from "@/components/Tabs/Tab";
import VenueShortlist from "@/views/Widgets/Inspections/VenueShortlist";
import VenueShortListDetailed from "@/views/Widgets/Inspections/VenueShortListDetailed";
import VenueShortListMap from "@/views/Widgets/Inspections/VenueShortListMap";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import tableMixinLeads from "@/helpers/tableMixinLeads";
import moment from 'moment';

// custom components
import leadFilters from "@/components/Leads/leadFilters";

export default {
  mixins: [tableMixinLeads],
  data() {
    return {
      propsToSearch: "name",
      leadId: parseInt(this.$route.params.id),
      leadEventId: parseInt(this.$route.params.eventId),
      currentComponent: "listView",
      searchQuery: "",
      loading: true,
    };
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Select.name]: Select,
    [Option.name]: Option,
    Collapse,
    CollapseItem,
    VenueShortlist,
    listView: VenueShortListDetailed,
    mapView: VenueShortListMap,
    leadFilters,
  },
  async created() {
    await this.$store.dispatch("shortlist/getShortlist", this.leadEventId);
    this.loading = true;
  },
  computed: {
    venues() {
      return this.$store.state.venues.items;
    },
  },
  methods: {
    swapComponent: function (component) {
      this.currentComponent = component;
    },
    createInspection: function () {
      this.$router.push({ 
        name: "CreateNewInspection", 
        params: { 
          id: this.leadId,
          eventId: this.leadEventId,
        },
      }).catch((err) => {
        this.$router.push({ name: "CreateNewInspection", 
          params: { 
            id: this.leadId,
            eventId: this.leadEventId,
          },
        }); // Not sure this happens, vue router cancelling to push as a new route. 
      });
    },
    // get filtred array from child component
    updateVenues(filteredVenues) {
      // Filtred data after totalFilter
      this.searchedData = filteredVenues;
      this.searchQueryMethod(this.searchQuery);
    },
  },
};
</script>

<style lang="scss">
.custom-search .form-group {
  margin-bottom: 0;
}

.custom-filters {
  a:hover {
    background: grey;
  }

  .card-header {
    padding: 0;
    border-bottom: 0;
  }
  .card {
    box-shadow: none;
  }

  .card-header:after {
    right: 0;
  }

  .card-body {
    padding: 1.5rem 0 0 0;
  }
}
</style>
