<template>
  <div v-loading="venues.loading" v-if="shortlisted" class="venues-list">
    <venues-map-view
      @select="addToShortlist" @remove="removeFromShortlist" :selected="shortlisted"
      :venues="venues" addToListLabel="Add To Inspections"></venues-map-view>
  </div>
</template>
<script>
import { Loading } from 'element-ui';
import VenuesMapView from "@/views/Widgets/Venues/VenuesMapView.vue";
export default {
  components: {
    VenuesMapView,
  },
  props: {
    venues: {
      type: Array,
    },
    leadId: {
      required: true,
    },
    leadEventId: {
      required: true,
    },
  },
  computed: {
    shortlisted() {
      return this.$store.getters['shortlist/getShortListByLeadEventId'](this.leadEventId);;
    },
  },
  methods: {
    async addToShortlist(venue) {
      const loading = this.loadingState();
      await this.$store.dispatch("shortlist/addShortlistVenue", {
        leadEventId: this.leadEventId,
        venueId: venue.id,
      });
      loading.close();
    },
    async removeFromShortlist(venue) {
      const loading = this.loadingState();
      await this.$store.dispatch("shortlist/removeShortlistVenue", {
        leadEventId: this.leadEventId,
        venueId: venue.id,
      });
      loading.close();
    },
    loadingState() {
      return Loading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },
  },
};

</script>