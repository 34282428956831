<template>
  <div class="venue-list-detailed" v-loading="loading">
    <venues-list-view
      :isLoading="loading" @select="addToShortlist" @remove="removeFromShortlist" :selected="shortlisted"
      :venues="venues" addToListLabel="Add To Inspections"></venues-list-view>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import VenuesListView from "@/views/Widgets/Venues/VenuesListView.vue";
export default {
  name: "venue-list-detailed",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    VenuesListView,
  },
  props: {
    leadEventId: {
      required: true,
    },
    leadId: {
      required: true,
    },
    venues: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    await this.$store.dispatch("venues/getAll", {lead_id: this.leadId});
    await this.$store.dispatch("shortlist/getShortlist", this.leadEventId);
    this.loading = false;
  },
  computed: {
    shortlisted() {
      return this.$store.getters['shortlist/getShortListByLeadEventId'](this.leadEventId);
    },
  },
  methods: {
    isShortlisted(venue) {
      let item = this.shortlisted.find((item) => parseInt(item.id) === parseInt(venue.id));
      return !!item;
    },
    async addToShortlist(venue) {
      const loading = this.loadingState();
      await this.$store.dispatch("shortlist/addShortlistVenue", {
        leadEventId: this.leadEventId,
        venueId: venue.id,
      });
      loading.close();
    },
    async removeFromShortlist(venue) {
      const loading = this.loadingState();
      await this.$store.dispatch("shortlist/removeShortlistVenue", {
        leadEventId: this.leadEventId,
        venueId: venue.id,
      });
      loading.close();
    },
    loadingState() {
      return Loading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },
  },
};
</script>

<style>
.venues-list .thumbnail img {
  max-width: 125px;
}
.venues-list .description {
  font-size: 14px;
}

.venues-list .avatar-xxl {
  width: 100%;
  height: auto;
}
</style>
